import axios from "axios"
import ENV_CONFIG from '@/config/environment'

const apiPath1 = ENV_CONFIG.env_api;

function initialState() {
    return {
        medicalNecessitiesTabEnabled: false,
        requestRequirementsErr: false,
        requirementsInfo: [],
        medicalNecessities: null,
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        CLEAR_MED_NEC_DATA: function({ commit }) {
            commit('SET_MED_NEC_DATA', null);
        },
        GET_MED_NEC_DATA: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}PaRequirements/GetMedicalNecesities`, params, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    if(response.data) {
                      commit('SET_MED_NEC_DATA', response.data);
                    } else {
                        commit('SET_MED_NEC_DATA', null);
                    }

                    if(!response.data.requirementLists) commit('SET_REQUEST_REQUIREMENTS_ERR', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_REQUEST_REQUIREMENTS_ERR', err.response.data)
                    return err;
                });
        },
    },
    mutations: {
        SET_MED_NEC_DATA: function (state, data) {
            state.medicalNecessities = data;
        },
        SET_DISPLAY_TAB: function (state, enableTab) {
            state.medicalNecessitiesTabEnabled = enableTab;
        },
        SET_REQUEST_REQUIREMENTS_ERR: (state, err) => {
            state.requestRequirementsErr = err
        },
    },
    getters: {
        getPolicyUrlDocumentNames: (state) => {
            if(state?.requestRequirements && state?.requestRequirements?.policyUrls?.length){
                return state.requestRequirements.policyUrls.map( url => {
                    let splitOnSlash = url.split('/')
                    return  {
                        name: decodeURI(splitOnSlash.splice(-1)[0]) || 'Document',
                        url: url
                    }
                })
            }
            return false
        },
        medicalNecessitiesTabEnabled: (state) => {
            return state.medicalNecessitiesTabEnabled;
        }
    }
}