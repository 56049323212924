export const EPIC_URL_PARAM_NAMES = {
	TOKEN: "token",
	PA_PERSON_ID: "paPersonId",
	CUSTOMER_ID: "customerId",
	DEPARTMENT_ID: "departmentId",
	EXPIRATION: "expiration",
	LAST_2FA_METHOD: "last2FAMethod",
	TWO_FACTOR_ENABLED: "twoFactorEnabled",
	TWO_FACTOR_REQUIRED: "twoFactorRequired",
	PA_REQUEST_ID: "paRequestId"
}